import { PACKAGE_CLASSNAME } from '../../constants';
import { BIG_SEARCHBAR_SELECTOR } from './constants';

/**
 * Gets the big search bar offset, by calculating how many pixels the screen needs to be scrolled for it to be concealed.
 */
export const get = (bigSearchBarSelector = BIG_SEARCHBAR_SELECTOR) => {
    const element = document.querySelector(bigSearchBarSelector);

    if (!element) {
        return;
    }

    const { height: headerHeight, top: headerTop } = document
        .querySelector(`.${PACKAGE_CLASSNAME}`)
        .getBoundingClientRect();
    const headerOffset = headerTop + headerHeight;

    const { top, height } = element.getBoundingClientRect();
    const elementOffset = top + height;

    const offset = elementOffset - headerOffset;

    return offset;
};
