import { PACKAGE_CLASSNAME } from '../../constants';
import { SUB_CATEGORIES_SELECTOR } from './constants';

/**
 * Gets the big search bar offset, by calculating how many pixels the screen needs to be scrolled for it to be concealed.
 */
export const get = () => {
    const element = document.querySelector(SUB_CATEGORIES_SELECTOR);

    if (!element) {
        return;
    }

    const { height: headerHeight, top: headerTop } = document
        .querySelector(`.${PACKAGE_CLASSNAME}`)
        .getBoundingClientRect();
    const headerOffset = headerTop + headerHeight;

    const { top, height } = element.getBoundingClientRect();
    const elementOffset = top + height;

    const offset = elementOffset - headerOffset;

    return offset;
};
