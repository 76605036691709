/**
 * The Big search bar selector, used to calculate offsets between the header search bar and the hero one.
 * @type {String}
 */
export const BIG_SEARCHBAR_SELECTOR = '.logged-out-homepage .search-bar-package';

/**
 * The Big search bar selector in catalog page, used to calculate offsets between the header search bar and the hero one.
 * @type {String}
 */
export const MAIN_CONTENT_BIG_SEARCHBAR_SELECTOR = '.main-content .search-bar-package';
