import React, { useEffect, useState, useCallback } from 'react';
import { object } from 'prop-types';
import { throttle } from 'lodash';
import ResourcesTab from '@fiverr-private/resources_tab/src/ResourcesTab';
import { getContext } from '@fiverr-private/fiverr_context';
import { SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';
import Nav from '../../lib/Nav';
import withContext from '../../lib/withContext';
import * as bigSearchBarOffset from '../../utils/bigSearchBarOffset';
import * as subCategoriesOffset from '../../utils/subCategoriesOffset';
import { GuestBecomeSellerLink } from '../../components/GuestLinks/GuestBecomeSellerLink';
import JoinLink from '../../components/JoinLink';
import Layout from '../../components/Layout';
import LeanLocaleSettingsLink from '../../components/LocaleSettingsLinks/LeanLocaleSettingsLink';
import LoginLink from '../../components/LoginLink';
import { Logo } from '../../components/Logo';
import MainRow from '../../components/MainRow';
import Navicon from '../../components/Navicon';
import SearchAnimated from '../../components/SearchAnimated';
import toggleCategoriesNavigation from '../../lib/toggleCategoriesNavigation';
import { BusinessProSolutionsNavigation } from '../../components/BusinessProSolutionsNavigation';

import './style.scss';
import './categoriesStyles.scss';

export const LoggedOutHomepage = ({ rollouts }) => {
    const { locale } = getContext();

    const isInRevampRollout = rollouts.inMeerkatsLohpRevampTestOut && locale !== SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE;

    const [isTransparent, setIsTransparent] = useState(!isInRevampRollout);
    const [showSearch, setShowSearch] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleScroll = useCallback(
        throttle(() => {
            const scrollDistance = window.scrollY;
            const transparent = scrollDistance === 0;
            const showCategoriesAndSearch = !transparent && scrollDistance >= bigSearchBarOffset.get();

            const shouldShowSearch = !transparent && bigSearchBarOffset.get() <= 0;
            const shouldShowCategories = !transparent && subCategoriesOffset.get() <= 0;

            setIsTransparent(!isInRevampRollout && transparent);
            setShowSearch(isInRevampRollout ? shouldShowSearch : showCategoriesAndSearch);
            toggleCategoriesNavigation(isInRevampRollout ? shouldShowCategories : showCategoriesAndSearch);
        }, 100),
        []
    );

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Trigger once on mount, to account for a pre-scrolled window

        return () => window.removeEventListener('scroll', handleScroll);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout isTransparent={isTransparent} className="logged-out-homepage-header">
            <MainRow>
                <Navicon />
                <Logo />
                <SearchAnimated show={showSearch} rollouts={rollouts} />
                <Nav alignToRight className="nav-with-space">
                    <Nav.Item className="nav-solution-button" displayFromMD>
                        <BusinessProSolutionsNavigation isTransparent={isTransparent} />
                    </Nav.Item>
                    <Nav.Item displayFromMD>
                        <ResourcesTab headerType="logged_out" isTransparent={isTransparent} />
                    </Nav.Item>
                    <Nav.Item displayFromLG>
                        <LeanLocaleSettingsLink />
                    </Nav.Item>
                    <Nav.Item displayFromMD>
                        <GuestBecomeSellerLink />
                    </Nav.Item>
                    <Nav.Item displayFromSM>
                        <LoginLink />
                    </Nav.Item>
                    <Nav.Item>
                        <JoinLink />
                    </Nav.Item>
                </Nav>
            </MainRow>
        </Layout>
    );
};

LoggedOutHomepage.propTypes = {
    rollouts: object,
};

LoggedOutHomepage.defaultProps = {
    rollouts: {},
};

/**
 * @type {React.FC}
 */
export default withContext(LoggedOutHomepage);
