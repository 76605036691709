import { CATEGORIES_MENU_SELECTOR } from './constants';

/**
 * A reference to the categories navigation element.
 * @type {HTMLElement}
 */
let element = null;

/**
 * Shows/hides the categories navigation menu.
 * @param {Boolean} show Whether to show/hide.
 */
const toggle = (show) => {
    element = element || document.querySelector(CATEGORIES_MENU_SELECTOR);

    if (!element) {
        return;
    }

    element.classList.toggle('shown', show);
};

export default toggle;
